var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sourceForm"},[_c('div',{staticClass:"sourceAdd"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"hasHeader":false,"submitText":'完成',"submitBefore":_vm.submitBefore,"submitError":_vm.submitError},scopedSlots:_vm._u([{key:"saveBeforeSlot",fn:function(){return [_c('v-button',{attrs:{"text":"保存"},on:{"click":_vm.handelSubmitClick}})]},proxy:true}])},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"数据源名称","rules":[{ required: true, message: '请输入名称', trigger: 'blur' }],"prop":"name"}},[_c('v-input',{attrs:{"width":400,"placeholder":"请输入数据源名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"数据库连接配置（jdbc）","rules":[
          {
            required: true,
            message: '请输入数据库连接配置',
            trigger: 'blur',
          },
        ],"prop":"url"}},[_c('v-input',{attrs:{"width":400,"placeholder":"请输入数据库连接配置"},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}})],1),_c('el-form-item',{attrs:{"label":"用户名","rules":[
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur',
          },
        ],"prop":"username"}},[_c('v-input',{attrs:{"width":400,"placeholder":"请输入用户名"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('el-form-item',{attrs:{"label":"密码","rules":[
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],"prop":"password"}},[_c('v-input',{attrs:{"width":400,"placeholder":"请输入密码"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('el-form-item',{attrs:{"label":"同步方式","rules":[
          {
            required: true,
            message: '请选择同步方式',
            trigger: 'blur',
          },
        ],"prop":"mode"}},[_c('v-radio',{attrs:{"radioObj":_vm.radioObj},on:{"change":_vm.change},model:{value:(_vm.form.mode),callback:function ($$v) {_vm.$set(_vm.form, "mode", $$v)},expression:"form.mode"}})],1),_c('el-form-item',{attrs:{"label":"抽取数据脚本（sql）","rules":[
          {
            required: true,
            validator: _vm.validateSql,
            trigger: 'blur',
          },
        ],"prop":"sql"}},[_c('v-input',{attrs:{"width":320,"placeholder":"请输入数据库连接配置"},model:{value:(_vm.form.sql),callback:function ($$v) {_vm.$set(_vm.form, "sql", $$v)},expression:"form.sql"}}),_c('v-button',{staticClass:"ml",attrs:{"text":"预览"},on:{"click":function($event){return _vm.watchInfo()}}})],1),(_vm.requestList.length > 0)?_c('div',{staticClass:"workSheet"},[_c('div',{staticClass:"workSheet-title"},[_vm._v("抽取字段预览：")]),_c('v-table',{ref:"vTable",attrs:{"tableData":_vm.requestList,"headers":_vm.headers},scopedSlots:_vm._u([{key:"operateTeSlot",fn:function(scope){return [_c('div',{staticClass:"opera-btn"},[_c('v-select',{attrs:{"multiple":true,"width":116,"placeholder":'主键ID',"options":_vm.options},on:{"change":_vm.toEdit,"remove":_vm.remove},model:{value:(_vm.requestList[scope.row.$index].value),callback:function ($$v) {_vm.$set(_vm.requestList[scope.row.$index], "value", $$v)},expression:"requestList[scope.row.$index].value"}})],1)]}}],null,false,212412802)})],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }